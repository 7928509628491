//Match Height
$(function() {
    stats();

    if ($(window).width() > 992) {
        $('.equal-height').matchHeight();
    }

    var str = window.location.pathname;
    var url;

    if (str == '/') {
        url = '.home'
    }
    else if (str == '/lobbying' || str == '/delivering-economic-growth' || str == '/delivering-differently') {
        url = '.what-we-do';
    }
    else {
        url = str.replace('/', '.');
    }

    $(url).addClass('active');

});

function stats() {
    var numberSliders = document.querySelectorAll('.js-animated-stat')

    if (! numberSliders.length) {
        return
    }

    for (var i = 0; i < numberSliders.length; i++) {
        var numberSlider = numberSliders[i];
        var value = numberSlider.querySelector('.js-stat').innerText;
        var valueArray = Array.from(value)

        for (var j = 0; j < valueArray.length; j++) {
            var character = valueArray[j];

            if (characterIsNumeric(character)) {
                valueArray[j] = buildNumberColumn(character);
            }
        }

        numberSlider.querySelector('.js-stat').innerHTML = valueArray.join('');

        var sliderObserver = new IntersectionObserver(function (numberSlider) {
            var slider = numberSlider[0];

            if (slider.isIntersecting) {
                var renderedColumns = slider.target.querySelector('.js-stat').querySelectorAll('.digit-wrap');

                for (var k = 0; k < renderedColumns.length; k++) {
                    renderedColumns[k].style.bottom = 'calc(-8px + (-63px * (10 + ' + renderedColumns[k].dataset.value + ')))';
                }
            }
        }, {
            root: null,
            threshold: 1,
            rootMargin: '50px 0px -50px 0px'
        });

        sliderObserver.observe(numberSlider);
    }
}

function characterIsNumeric(character) {
    return /^\d+$/.test(character);
}

function buildNumberColumn(digit) {
    var content = '<div class="number-column"><div class="digit-wrap" data-value="' + digit + '">';

    for (var i = 9; i >= 0; i--) {
        content += '<span class="digit">' + i + '</span>';
    }
    for (var i = 9; i >= 0; i--) {
        content += '<span class="digit">' + i + '</span>';
    }

    content += '</div></div>';

    return content;
}